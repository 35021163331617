import { Typography } from "@material-ui/core"
import { connect } from "react-redux"
import NotFoundImg from "../../assets/images/404.png"
import { translate } from "../../lang"

function NotFoundContent(props) {
  let defaultText = translate("sorr_no_page_found", props.data2.lang)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        paddingTop: "100px",
      }}
    >
      <img
        alt="not found"
        src={NotFoundImg}
        style={{ width: "400px", height: "300px" }}
      ></img>
      <Typography variant="h5">
        {props.message ? props.message : defaultText}
      </Typography>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { data2: state.data }
}

export default connect(mapStateToProps)(NotFoundContent)
