import axios from "axios"
import { projectInfo } from "./utils"

const baseURL = projectInfo.baseURL

const ENDPOINT = projectInfo.printURL

const instance = axios.create({
  baseURL: baseURL,
})

export default instance
export { ENDPOINT }
