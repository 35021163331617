import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { logoutUser } from "../../actions/userActions"
import { isAuthenticated } from "../../utils"

function SigninRoute({ children, user, logout, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated(user, logout)) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        } else {
          return children
        }
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (user) => {
      dispatch(logoutUser())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninRoute)
