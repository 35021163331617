import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { applyMiddleware, combineReducers, createStore } from "redux"
import { createLogger } from "redux-logger" // Redux logging tool
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import App from "./components/App"
import "./index.css"
import reducers from "./reducers/index"
import RTL from "./rtl"
import theme from "./theme"

const logger = createLogger() // create logger from redux-logger
const reducer = combineReducers(reducers)
const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store

// react out of the box support envirtoment
// https://create-react-app.dev/docs/adding-custom-environment-variables/

if (process.env.NODE_ENV === "production") {
  store = createStore(persistedReducer)
} else {
  store = createStore(persistedReducer, applyMiddleware(logger))
}

let persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <RTL>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </RTL>
    </ThemeProvider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
)
