import { createMuiTheme } from "@material-ui/core/styles"
import NizarBoldWoff from "./assets/fonts/Nizar-Bold.woff"
import NizarWoff from "./assets/fonts/Nizar-Regular.woff"
import { projectInfo } from "./utils"

const Nizar = {
  fontFamily: "Nizar",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Nizar'),
    local('Nizar-Regular'),
    url(${NizarWoff}) format('woff2')
  `,
}

const NizarBold = {
  fontFamily: "Nizar",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
    local('Nizar'),
    local('Nizar-Bold'),
    url(${NizarBoldWoff}) format('woff2')
  `,
}

const options = {
  direction: "rtl",
  palette: {
    primary: {
      main: projectInfo.mainColor,
    },
    secondary: {
      main: "#eac661",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Nizar",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Nizar, NizarBold],
      },
    },
  },
}

const theme = createMuiTheme(options)

export default theme
export { options }
