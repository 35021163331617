import * as actionTypes from "../actions/actionTypes"

const data = (
  state = {
    message: "",
    lang: "ku",
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_DATA: //  adding information
      return { ...state, ...action.payload }
    case actionTypes.CLEAR_DATA: //  adding information
      return { ...action.payload }

    default:
      // default state of the reducer
      return state
  }
}

export default data
