/* eslint-disable jsx-a11y/no-access-key */
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { List as ListIcon } from "@material-ui/icons"
import Apartment from "@material-ui/icons/Apartment"
import Category from "@material-ui/icons/Category"
import ChevronRight from "@material-ui/icons/ChevronRight"
import DriveEtaRounded from "@material-ui/icons/DriveEtaRounded"
import ExitToApp from "@material-ui/icons/ExitToApp"
import Facebook from "@material-ui/icons/Facebook"
import Group from "@material-ui/icons/Group"
import History from "@material-ui/icons/History"
import Home from "@material-ui/icons/Home"
import Menu from "@material-ui/icons/Menu"
import Money from "@material-ui/icons/Money"
import Notifications from "@material-ui/icons/Notifications"
import Print from "@material-ui/icons/Print"
import Receipt from "@material-ui/icons/Receipt"
import AccountBalanceWalletSharp from "@material-ui/icons/AccountBalanceWalletSharp"
import clsx from "clsx"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { setData as setDatafn } from "../actions/dataActions"
import { logoutUser } from "../actions/userActions"
import arFlag from "../assets/images/iq.png"
import kuFlag from "../assets/images/ku.png"
import boxSVG from "../assets/svg/box.svg"
import axios from "../axiosConfig"
import { translate } from "../lang"
import { useLocalStorage } from "../useLocalStorage"
import { projectInfo } from "../utils"
import { toastSuccess } from "./App"
import PermissionPainter from "./utils/PermissionPainter"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
  },
}))

function AppPanel({
  children,
  logout,
  setData,
  setLoading,
  user,
  data2,
  history,
  staticContext,
  ...rest
}) {
  const classes = useStyles()
  const [open, setOpen] = useLocalStorage("AppPanel_open", false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const logoutUserFn = () => {
    setLoading(true)
    axios
      .post("logout")
      .then(() => {
        setLoading(false)
        logout()
        window.localStorage.clear()
        setData({
          message: "",
          lang: data2.lang,
        })

        toastSuccess(translate("logout_success_message", data2.lang))
      })
      .catch((error) => {
        setLoading(false)
        logout()
        window.localStorage.clear()
      })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>

          <Link color="inherit" href="/" style={{ textDecoration: "none" }}>
            <IconButton color="inherit">
              <Typography variant="h6" noWrap>
                {projectInfo.companyName}
              </Typography>
            </IconButton>
          </Link>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex"
            flexGrow={1}
          >
            <Tooltip title={`${translate("user_name", data2.lang)} {H}`}>
              <IconButton
                color="inherit"
                accessKey="h"
                onClick={() => {
                  history.push("/")
                }}
              >
                <Typography variant="h6" noWrap>
                  {user.name}
                </Typography>
              </IconButton>
            </Tooltip>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "0.3vw",
                marginRight: "0.3vw",
              }}
            >
              <Tooltip
                title={`${translate("language", data2.lang)} {Z}`}
                accessKey="Z"
              >
                <img
                  src={data2.lang === "ku" ? arFlag : kuFlag}
                  alt="ku/ar flag"
                  width="24px"
                  height="24px"
                  className={classes.icon}
                  onClick={() => {
                    if (data2.lang === "ku") {
                      setData({
                        lang: "ar",
                      })
                    } else {
                      setData({
                        lang: "ku",
                      })
                    }
                  }}
                />
              </Tooltip>
            </div>
            <Tooltip
              title={`${translate("logout", data2.lang)} {L}`}
              accessKey="l"
              onClick={logoutUserFn}
            >
              <IconButton color="inherit">
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRight />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              history.push("/")
            }}
          >
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary={translate("home", data2.lang)} />
          </ListItem>

          <PermissionPainter permissions={["root", "admin", "driver"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/posts")
              }}
            >
              <ListItemIcon>
                <img src={boxSVG} alt="box icon" height="21px" />
              </ListItemIcon>
              <ListItemText primary={translate("post", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["driver"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/drivers/wallet")
              }}
            >
              <ListItemIcon>
                <AccountBalanceWalletSharp></AccountBalanceWalletSharp>
              </ListItemIcon>
              <ListItemText primary={translate("wallet", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["driver"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/drivers/kashf")
              }}
            >
              <ListItemIcon>
                <Receipt></Receipt>
              </ListItemIcon>
              <ListItemText primary={translate("account_report", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root", "admin"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/pages")
              }}
            >
              <ListItemIcon>
                <Facebook />
              </ListItemIcon>
              <ListItemText primary={translate("page", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["page-owner"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/pages/product")
              }}
            >
              <ListItemIcon>
                <img src={boxSVG} alt="box icon" height="21px" />
              </ListItemIcon>
              <ListItemText primary={translate("page", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["page-owner"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/pages/wallet")
              }}
            >
              <ListItemIcon>
                <AccountBalanceWalletSharp></AccountBalanceWalletSharp>
              </ListItemIcon>
              <ListItemText primary={translate("wallet", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["page-owner"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/pages/kashf")
              }}
            >
              <ListItemIcon>
                <Receipt></Receipt>
              </ListItemIcon>
              <ListItemText primary={translate("account_report", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root", "admin"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/drivers")
              }}
            >
              <ListItemIcon>
                <DriveEtaRounded />
              </ListItemIcon>
              <ListItemText primary={translate("driver", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/managers")
              }}
            >
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary={translate("managers", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root", "admin"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/cities")
              }}
            >
              <ListItemIcon>
                <Apartment />
              </ListItemIcon>
              <ListItemText primary={translate("city_and_towns", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root", "admin"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/categories")
              }}
            >
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary={translate("category", data2.lang)} />
            </ListItem>
          </PermissionPainter>

          <PermissionPainter permissions={["root"]}>
            <ListItem
              button
              onClick={() => {
                history.push("/benefit")
              }}
            >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={translate("benefit", data2.lang)} />
            </ListItem>
          </PermissionPainter>
        </List>

        <PermissionPainter permissions={["root", "admin"]}>
          <ListItem
            button
            onClick={() => {
              history.push("/costs")
            }}
          >
            <ListItemIcon>
              <Money />
            </ListItemIcon>
            <ListItemText primary={translate("cost", data2.lang)} />
          </ListItem>
        </PermissionPainter>

        <PermissionPainter permissions={["root", "admin"]}>
          <ListItem
            button
            onClick={() => {
              history.push("/history")
            }}
          >
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText primary={translate("history", data2.lang)} />
          </ListItem>
        </PermissionPainter>

        <PermissionPainter permissions={["root", "admin"]}>
          <ListItem
            button
            onClick={() => {
              history.push("/printers")
            }}
          >
            <ListItemIcon>
              <Print />
            </ListItemIcon>
            <ListItemText primary={translate("printer", data2.lang)} />
          </ListItem>
        </PermissionPainter>

        <PermissionPainter permissions={["root", "admin"]}>
          <ListItem
            button
            onClick={() => {
              history.push("/notification")
            }}
          >
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary={translate("notification", data2.lang)} />
          </ListItem>
        </PermissionPainter>

        <Divider />
        <List onClick={logoutUserFn}>
          <ListItem button>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary={translate("logout", data2.lang)} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div {...rest}>{children}</div>
      </main>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user, data2: state.data }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutUser())
    },
    setData: (data) => {
      dispatch(setDatafn(data))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppPanel))
