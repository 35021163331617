import * as actionTypes from "./actionTypes"

export const loginUser = (user) => {
  let data = user
  return { type: actionTypes.LOGIN_USER, payload: { ...data } }
}

export const logoutUser = () => {
  return { type: actionTypes.LOGOUT_USER, payload: {} }
}
