import * as actionTypes from "../actions/actionTypes"

const user = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER: //  adding information
      return { ...state, ...action.payload }
    case actionTypes.LOGOUT_USER: //  adding information
      return { ...action.payload }

    default:
      // default state of the reducer
      return state
  }
}

export default user
