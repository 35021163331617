const translation = {
  home: {
    ku: "سەرەکی",
    ar: "رئيسي",
  },
  post: {
    ku: "پۆست",
    ar: "البريد",
  },
  page: {
    ku: "پەیج",
    ar: "صفحة",
  },
  account_report: {
    ku: "کەشف حساب",
    ar: "تقرير الحساب",
  },
  expire_token_message: {
    ku: "رێگەپێدانت  بەسەر چووە، تکایە دوبارە کرداری چونەژورەوە ئەنجام بدە.",
    ar: "انتهت صلاحية إذنك. يرجى إعادة تسجيل الدخول.",
  },
  logout_success_message: {
    ku: "بە سەرکەوتوی کردرای چونەدەرەوە تۆمار کرا",
    ar: "تسجيل الخروج بنجاح",
  },
  user_name: {
    ku: "ناوی بەکارهێنەر",
    ar: "اسم المستخدم",
  },
  language: {
    ku: "زمان",
    ar: "اللغة",
  },
  logout: {
    ku: "چونەدەرەوە",
    ar: "تسجيل الخروج",
  },
  driver: {
    ku: "شۆفێر",
    ar: "سائق",
  },
  managers: {
    ku: "بەڕێوەبەرایەتی",
    ar: "إدارة",
  },
  city_and_towns: {
    ku: "شار و شارۆچکە",
    ar: "المدن والبلدات",
  },
  category: {
    ku: "جۆر",
    ar: "نوع",
  },
  benefit: {
    ku: "قازانج",
    ar: "ربح",
  },
  cost: {
    ku: "خەرجی",
    ar: "النفقات",
  },
  history: {
    ku: "مێژوو",
    ar: "تاريخ",
  },
  printer: {
    ku: "پرنتەر",
    ar: "طابعة",
  },
  notification: {
    ku: "ئاگانامە",
    ar: "الاخطارات",
  },
  debit_error_message: {
    ku: "هەڵە هەیە لە هێنانی جزدان",
    ar: "هناك خطأ في إحضار المحفظة",
  },
  get_info_error_message: {
    ku: "هەڵە هەیە لە هێنانی لیستی زانیاریەکان",
    ar: "يوجد خطأ في حصول قائمة البيانات",
  },
  debit: {
    ku: "جزدان",
    ar: "محفظة",
  },
  remain: {
    ku: "ماوە",
    ar: "بقي",
  },
  name: {
    ku: "ناو",
    ar: "اسم",
  },
  admin: {
    ku: "ئەدمین",
    ar: "ادمین",
  },
  get_amount: {
    ku: "بڕی وەرگیراو",
    ar: "المبلغ المستلم",
  },
  paid_amount: {
    ku: "بڕی دراو",
    ar: "المبلغ المدفوع",
  },
  todays_post: {
    ku: "پۆستەکانی ئەمڕۆ",
    ar: "البريدات اليوم",
  },
  posts_price: {
    ku: "نرخی بەریدەکان",
    ar: "سعر البريدات",
  },
  transactions: {
    ku: "مامەڵەکان",
    ar: "المعاملات",
  },
  start_date: {
    ku: "بەرواری سەرەتا",
    ar: "تاريخ البدء",
  },
  end_date: {
    ku: "بەرواری کۆتا",
    ar: "تاريخ الانتهاء",
  },
  search_transactions: {
    ku: "گەڕانی مامەڵەکان",
    ar: "البحث في المعاملات",
  },
  total_get_amount: {
    ku: "کۆی گشتی بڕی وەرگیراو",
    ar: "إجمالي المبلغ المستلم",
  },
  total_paid_amount: {
    ku: "کۆی گشتی بڕی دراو",
    ar: "المبلغ الإجمالي المدفوع",
  },
  please_enter_username_and_password: {
    ku: "تکایە ناوی بەکارهێنەر و وشەی تێ پەڕ داخل بکە",
    ar: "الرجاء إدخال اسم المستخدم وكلمة المرور",
  },
  login_success_message: {
    ku: "چونە ژورەوە بە سەرکەوتوی تۆمار کرا",
    ar: "تم تسجيل دخول بنجاح",
  },
  login_error_message: {
    ku: "هەڵە هەیە لە کراری چونەژورەوەی بەکارهێنەر",
    ar: "يوجد خطأ في عملية تسجيل دخول المستخدم",
  },
  login: {
    ku: "چونەژورەوە",
    ar: "تسجيل الدخول",
  },
  phone_numer: {
    ku: "ژمارە مۆبایل",
    ar: "رقم الموبايل",
  },
  password: {
    ku: "وشەی تێپەڕ",
    ar: "كلمه مرور",
  },
  get_benefit_success_message: {
    ku: "بە سەرکەوتوی راپۆرتی کاتی دیاریکراو هێنرایەوە لە بنکەی زانیاری",
    ar: "حصلت بنجاح على تقرير عن تواريخ محددة من قاعدة البيانات",
  },
  error_getting_report_message: {
    ku: "هەڵە هەیە لە هێنانی راپۆرت",
    ar: "يوجد خطأ أثناء حصول التقرير",
  },
  perform: {
    ku: "ئەنجامدان",
    ar: "تنفيذ",
  },
  total_client_wallets: {
    ku: "کۆی جزدانی مشتەریەکان",
    ar: "إجمالي محافظ العملاء",
  },
  total_customers_goods: {
    ku: "کۆی پارەی کاڵاکانی مشتەری",
    ar: "المبلغ الإجمالي لأموال العملاء",
  },
  total_sum: {
    ku: "کۆی گشتی",
    ar: "المجموع الكلي",
  },
  drivers_wallet_total: {
    ku: "کۆی جزدانی سایەقەکان",
    ar: "مجموع محفظة السائقين",
  },
  drivers_total_money_goods: {
    ku: "کۆی پارەی کاڵاکانی لای سایەق",
    ar: "المبلغ الإجمالي مال من قبل السايق",
  },
  result: {
    ku: "ئەنجام",
    ar: "نتيجة",
  },
  spending: {
    ku: "مەسروف",
    ar: "مەسروف",
  },
  net_benefit: {
    ku: "قازانج سافی",
    ar: "ربح الصافي",
  },
  post_price: {
    ku: "نرخی پۆست",
    ar: "سعر البريد",
  },
  arrived: { ku: "گەشتووە", ar: "وصل" },
  returned_to_office: { ku: "کەڕاوە بۆ ئۆفیس", ar: "عاد إلى مکتب" },
  returned_to_page: { ku: "گەڕاوە بۆ پەیج", ar: "العودة إلى الصفحة" },
  rejected: { ku: "نایەوێت", ar: "رفضت" },
  phone_locked: { ku: "تەلەفۆنی داخراوە", ar: "الهاتف مغلق" },
  no_answer: { ku: "جواب ناداتەوە", ar: "لا جواب" },
  have_problem: { ku: "کێشەی تێدایە", ar: "لديك مشكلة" },
  taken_by_driver: { ku: "لای شۆفێر", ar: "التقطها السائق" },
  postpone: { ku: "دواخرا", ar: "تاجيل" },
  douplicate: { ku: "دووبارەیە", ar: "تكرار" },
  in_office: { ku: " لە ئۆفیس", ar: "في المكتب" },
  get_categiry_error_message: {
    ku: "هەڵە هەیە لە هێنانی لیستی جۆرەکان",
    ar: "حدث خطأ أثناء حصول قائمة بالأنواع",
  },
  type_name_required: {
    ku: "ناوی جۆر داواکراوە",
    ar: "اسم النوع مطلوب",
  },
  type_added_successfully: {
    ku: "جۆر بەسەرکەوتوی زیادکرا",
    ar: "النوع الذي تمت إضافته بنجاح",
  },
  there_is_an_error_adding_type: {
    ku: "هەڵە هەیە لە زیادکردنی جۆر",
    ar: "يوجد خطأ في إضافة نوع",
  },
  type_has_been_successfully_updated: {
    ku: "جۆر بەسەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد النوع بنجاح",
  },
  there_is_an_error_updating_type: {
    ku: "هەڵە هەیە لە نوێکردنەوەی جۆر",
    ar: "يوجد خطأ في تحديث النوع",
  },
  word: {
    ku: "وشە",
    ar: "كلمة",
  },
  search_for_categories: {
    ku: "گەڕانی جۆرەکان",
    ar: "البحث عن الفئات",
  },
  id: {
    ku: "ئایدی",
    ar: "المعرف",
  },
  update: {
    ku: "نوێکردنەوە",
    ar: "تحديث",
  },
  fetch_more_categories: {
    ku: "هیێنانی جۆری زیاتر",
    ar: "حصول المزيد من الأنواع",
  },
  add_category: {
    ku: "زیادکردنی جۆر",
    ar: "إضافة نوع",
  },
  update_category: {
    ku: "نوێکردنەوەی جۆر",
    ar: "تحديث النوع",
  },
  category_name: {
    ku: "ناوی جۆر",
    ar: "اكتب الاسم",
  },
  add: {
    ku: "زیادکردن",
    ar: "إضافة",
  },
  dismiss: {
    ku: "پەشیمان بونەوە",
    ar: "إلغاء الأمر",
  },
  categories: {
    ku: "جۆرەکان",
    ar: "انواع",
  },
  city_id_is_not_available: {
    ku: "ئایدی شار بەردەست نیە",
    ar: "معرف المدينة غير متوفر.",
  },

  city_name_is_not_available: {
    ku: "ناوی شار بەردەست نیە",
    ar: "اسم المدينة غير متوفر.",
  },
  enter_reasonable_price: {
    ku: "نرخی گونجاو داخل بکە",
    ar: "أدخل السعر الصحيح",
  },
  city_update_sucessfully: {
    ku: "شارەکە بەسەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد المدينة بنجاح",
  },
  city_update_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی شار",
    ar: "هناك خطأ في تحديث المدينة",
  },
  town_update_sucessfully: {
    ku: "شارۆچکەکە بەسەرکەوتوی زیادکرا",
    ar: "تمت إضافة المدينة بنجاح",
  },
  town_update_error: {
    ku: "هەڵە هەیە لە زیادکردنی شارۆچکەکە",
    ar: "هناك خطأ في إضافة المدينة",
  },
  get_city_list_error: {
    ku: "هەڵە هەیە لە هێنانی لیستی شارەکان",
    ar: "هناك خطأ في حصول قائمة المدن",
  },

  add_city: {
    ku: "زیادکردنی شار",
    ar: "زيادة المدينة",
  },
  city: {
    ku: "شار",
    ar: "مدينة",
  },
  city_name: {
    ku: "ناوی شار",
    ar: "اسم المدينة",
  },
  price: {
    ku: "نرخ",
    ar: "السعر",
  },
  update_city: {
    ku: "نوێکردنەوەی شار",
    ar: "تحديث المدينة",
  },
  towns: {
    ku: "شارۆچکەکان",
    ar: "البلدات",
  },
  town: {
    ku: "شارۆچکە",
    ar: "مدينة",
  },
  update_town: {
    ku: "نوێکردنەوەی شارۆچکە",
    ar: "تحديث المدينة",
  },
  add_town: {
    ku: "زیادکردنی شارۆچکە",
    ar: "إضافة مدينة",
  },
  town_name: {
    ku: "ناوی شارۆچکە",
    ar: "اسم البلدة",
  },
  please_write_town_name: {
    ku: "تکایە ناوی شاری بنوسە",
    ar: "يرجى كتابة اسم المدينة.",
  },
  town_add_successfully: {
    ku: "شار بە سەرکەوتوی زیادکرا",
    ar: "تمت إضافة المدينة بنجاح",
  },
  town_add_error: {
    ku: "هەڵە هەیە لە زیادکردنی شار",
    ar: "هناك خطأ في إضافة المدينة",
  },
  close: {
    ku: "داخستن",
    ar: "اغلاق",
  },
  no_expenses_available_between_selected_dates: {
    ku: "هیچ خەرجیەک  بەردەست نیە لەنێوان بەروارە هەلبژێردراوەکان",
    ar: "لا توجد مصاريف متاحة بين التواريخ المحددة",
  },
  error_fetching_expense_list: {
    ku: "هەڵە هەیە لە هێنانی لیستی خەرجیەکان",
    ar: "هناك خطأ في حصول قائمة الإنفاق",
  },
  expenses: {
    ku: "خەرجیەکان",
    ar: "النفقات",
  },
  index: {
    ku: "زنجیرە",
    ar: "سلسلة",
  },
  date: {
    ku: "بەروار",
    ar: "تاريخ",
  },
  amount: {
    ku: "بڕ",
    ar: "مبلغ",
  },
  note: {
    ku: "تێبینی",
    ar: "ملاحظه",
  },
  add_cost: {
    ku: "زیادکردنی خەرجی",
    ar: "زيادة الإنفاق",
  },
  please_fill_in_all_asterik_fields: {
    ku: "تکایە هەموو جۆرەکان کە بە ى نیشانە کراوە پڕ بکەرەوە",
    ar: "يرجى ملء جميع العناصر التي تحمل علامة",
  },
  add_cost_successfully: {
    ku: "خەرجی بە سەرکەوتوی زیادکرا",
    ar: "تم زيادة التكلفة بنجاح",
  },
  add_cost_error: {
    ku: "هەڵە هەیە لە زیادکردنی خەرجی",
    ar: "هناك خطأ في زيادة الإنفاق",
  },
  missing_field_to_update_cost: {
    ku: "زانیار تەوار بەردەست نیە بۆ نوێکردنەوەی خەرجی",
    ar: "لا تتوفر معلومات كافية لتحديثات التكلفة",
  },
  cost_update_successfully: {
    ku: "خەرجی بە سەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد التكلفة بنجاح",
  },
  cost_update_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی خەرجی",
    ar: "يوجد خطأ في تحديث التكلفة",
  },
  update_cost: {
    ku: "نوێکردنەوەی خەرجی",
    ar: "تكلفة التحديث",
  },
  fetching_driver_list_error: {
    ku: "هەڵە هەیە لە هێنانی لیستی شۆفێرەکان",
    ar: "هناك خطأ في حصول قائمة السائق",
  },
  drivers: {
    ku: "شۆفێرەکان",
    ar: "السائقين",
  },
  search_for_drivers: {
    ku: "گەڕانی شۆفێرەکان",
    ar: "البحث عن السائقين",
  },
  phone: {
    ku: "مۆبایل",
    ar: "الهاتف",
  },
  transfers: {
    ku: "حەواڵەکان",
    ar: "تحويلات",
  },
  transfers_new: {
    ku: "حەواڵەکان (نوێ)",
    ar: "تحويلات (جدید)",
  },
  posts: {
    ku: "پۆستەکان",
    ar: "البريدات",
  },
  add_driver: {
    ku: "زیادکردنی شۆفێر",
    ar: "إضافة السائق",
  },
  drivers_wallet: {
    ku: "جزدانی شۆفێرەکان",
    ar: "محفظة السائق",
  },
  password_dont_match: {
    ku: "وشە تێپەڕەکان هاوشێوەنین",
    ar: "كلمات المرور غير متطابقة",
  },
  weak_password_warning: {
    ku: "وشەی تێپەڕ کەمتر لە هەشت ٨ پیت و ژمارە + نیشانە",
    ar: "كلمة المرور أقل من ثمانية أحرف ٨ أحرف وعدد + علامات",
  },
  add_driver_successfully: {
    ku: "بەسەرکەوتوی شۆفێر زیاد کرا",
    ar: "تمت إضافة السائق بنجاح",
  },
  add_driver_error: {
    ku: "هەڵە هەیە لە زیادکردنی شۆفێر",
    ar: "هناك خطأ في السائق التشغيل",
  },
  confirm_password: {
    ku: "پشتراستکردنەوەی وشەی تێپەڕ",
    ar: "تحقق من كلمة المرور",
  },
  phone_number: {
    ku: "ژمارە مۆبایل",
    ar: "رقم الموبايل",
  },
  block: {
    ku: "بلۆک",
    ar: "بلوك",
  },
  yes: {
    ku: "بەڵێ",
    ar: "نعم",
  },
  no: {
    ku: "نەخێر",
    ar: "لا",
  },
  weak: {
    ku: "لاواز",
    ar: "ضعيف",
  },
  good: {
    ku: "باش",
    ar: "جيد",
  },
  strong: {
    ku: "بەهێز",
    ar: "قوي",
  },
  strong_password_guide: {
    ku: "تێبینی: بۆ دانانی وشەی تێپەڕی بەهێز پیتی بچوک و گەورە، ژمارە و هێما بە تێکەڵی بەکار بهێنە.",
    ar: "ملاحظة: لإدراج كلمات مرور قوية وأحرف صغيرة وكبيرة وأرقام ورموز بواسطة استخدم خليطا.",
  },
  update_driver_successfully: {
    ku: "بەسەرکەوتوی شۆفێر نوێکرایەوە",
    ar: "تم تجديد السائق بنجاح",
  },
  update_driver_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی شۆفێر",
    ar: "هناك خطأ في السائق التشغيل",
  },
  error_occurred_please_try_again: {
    ku: "هەڵە هەیە تکایە دوبارە هەوڵبدەرەوە",
    ar: "حدث خطأ يرجى المحاولة مرة أخرى",
  },
  update_driver: {
    ku: "نوێکردنەوەی شۆفێر",
    ar: "تحديث السائق",
  },
  transfer_operation_done_successfully: {
    ku: "کرداری حەواڵەکردن بە سەرکەوتوی ئەنجامدرا",
    ar: "تمت عملية النقل بنجاح",
  },
  transfer_operation_done_error: {
    ku: "هەڵە هەیە لە هێنانی ئەنجامدانی حەواڵەکردن",
    ar: "حدث خطأ أثناء عملية النقل",
  },
  update_transfer_operation_done_successfully: {
    ku: "کرداری نوێکردنەوەی حەواڵەکان بە سەرکەوتوی ئەنجامدرا",
    ar: "تم تنفيذ عملية تحديث النقل بنجاح",
  },
  update_transfer_operation_done_error: {
    ku: "هەڵە هەیە لە ئەنجامدانی کرداری نوێکردنەوەی حەواڵەکان",
    ar: "يوجد خطأ في إجراء تحديث عمليات النقل",
  },
  delete_transfer_operation_done_successfully: {
    ku: "کرداری سڕینەوەی حەواڵەکان بە سەرکەوتوی ئەنجامدرا",
    ar: "تم تنفيذ عملية حذف نقل بنجاح",
  },
  delete_transfer_operation_done_error: {
    ku: "هەڵە هەیە لە ئەنجامدانی کرداری سڕینەوەی حەواڵەکان",
    ar: "يوجد خطأ أثناء تنفيذ عملية الحذف",
  },
  no_transfer_available: {
    ku: "هیچ حەواڵەیەک  بەردەست نیە ",
    ar: "لا توجد نقل متاحة ",
  },
  get_transfer_list_error: {
    ku: "هەڵە هەیە لە هێنانی لیستی حەواڵەکان",
    ar: "هناك خطأ في حصول قائمة نقل",
  },
  error_getting_wallet: {
    ku: "هەڵە هەیە لە هێنانی جزدان",
    ar: "هناك خطأ في إحضار المحفظة",
  },
  not_necessaries_info_are_available_to_make_transfer: {
    ku: "زانیاری تەواو بەردەست نیە بۆ حەواڵە ، تکایە دوبارە هەوڵبدەرەوە",
    ar: "لا تتوفر معلومات الضروريات لإجراء النقل ",
  },
  wallet: {
    ku: "جزدان",
    ar: "محفظة",
  },
  transfers_selected: {
    ku: "حەواڵە دیاریکراوە",
    ar: "التحويلات المحددة",
  },
  post_transfer_operation_warning_message: {
    ku: "بە کردتەکردنت لەسەر بەڵێ حەواڵەی هەلبژێراوەکان ئەنجام دەدرێن",
    ar: "من خلال القيام بذلك على نعم ، سيتم إجراء تحويلات مختارة ",
  },
  transfering: {
    ku: "حەواڵەکردن",
    ar: "نقل",
  },
  update_transfer_operation_warning_message: {
    ku: "بە کردتەکردنت لەسەر بەڵێ حەواڵەی هەلبژێراوەکان نوێدەبنەوە",
    ar: "بالنقر فوق نعم، سيتم تجديد تحويلات مختارة ",
  },
  delete_transfer_operation_warning_message: {
    ku: "بە کردتەکردنت لەسەر بەڵێ حەواڵەی هەلبژێراوەکان دەسڕینەوە بە یەکجاری!",
    ar: "بالنقر فوق نعم ، سنقوم بحذف تحويلات مختارة إلى الأبد!",
  },
  delete: {
    ku: "سڕینەوە",
    ar: "حذف",
  },
  user: {
    ku: "بەکارهێنەر",
    ar: "مستخدم",
  },
  status: {
    ku: "حاڵەت",
    ar: "حال",
  },
  driver_price: {
    ku: "نرخی شۆفێر",
    ar: "سعر السائق",
  },
  be_careful: {
    ku: "ئاگاداربە!",
    ar: "انظر!",
  },
  no_information_available_between_selected_dates: {
    ku: "هیچ زانیاریەک  بەردەست نیە لەنێوان بەروارە هەلبژێردراوەکان",
    ar: "لا توجد معلومات متوفرة بين التواريخ المحددة",
  },
  error_fetching_data: {
    ku: "هەڵە هەیە لە هێنانی زانیاریەکان",
    ar: "يوجد خطأ في حصول البيانات",
  },
  driver_account_report: {
    ku: "کەشف حسابی شۆفێر",
    ar: "تقرير حساب السائق",
  },
  transaction_type: {
    ku: "مامەڵە",
    ar: "المعاملات",
  },
  reciever_name: {
    ku: "وەرگر",
    ar: "المستلم",
  },

  city_price: {
    ku: "نرخی شار",
    ar: "سعر المدينة",
  },
  sorry_no_information_available: {
    ku: "ببورە, هیچ زانیاریەک بەردەست نیە",
    ar: "عذرا، لا تتوفر أي معلومات.",
  },
  sorting: {
    ku: "ریزبەندکردن",
    ar: "صنف",
  },
  sort_to: {
    ku: "ریزبەند بۆ",
    ar: "قائمة إلى",
  },

  from: {
    ku: "لە",
    ar: "من",
  },
  before: {
    ku: "پێشتر",
    ar: "قبل",
  },
  after: {
    ku: "پاشتر",
    ar: "بعد",
  },
  viewing: {
    ku: "پیشاندانی",
    ar: "عرض",
  },
  jump_to: {
    ku: "بڕۆ بۆ",
    ar: "الانتقال إلى",
  },
  all: {
    ku: "هەموو",
    ar: "كل",
  },
  clearing: {
    ku: "پاکردنەوەی",
    ar: "تنظيف",
  },
  delete_area: {
    ku: "ناوچەی سڕینەوە",
    ar: "حذف المنطقة",
  },
  single_item: {
    ku: "دانە",
    ar: "عنصر",
  },
  download: {
    ku: "داگرتن",
    ar: "تحميل",
  },
  filtering: {
    ku: "فلتەرکردن",
    ar: "تصفيه",
  },
  printing: {
    ku: "چاپکردن",
    ar: "طبع",
  },
  search: {
    ku: "گەڕان",
    ar: "بحث",
  },
  view_columns: {
    ku: "پیشاندانی ستونەکان",
    ar: "إظهار الأعمدة",
  },
  error_fetching_list_of_posts: {
    ku: "هەڵە هەیە لە هێنانی لیستی پۆستەکان",
    ar: "يوجد خطأ في حصول القائمة البريدية",
  },
  page_owner: {
    ku: "خاوەن پەیج",
    ar: "مالك الصفحة",
  },
  address: {
    ku: "ناونیشان",
    ar: "عنوان",
  },
  driver_posts: {
    ku: "پۆستەکانی شۆفێر",
    ar: "البريدات السائق",
  },
  driver_posts_list: {
    ku: "لیستی کاڵاکانی شۆفێر",
    ar: "قائمة متاع السائق",
  },
  driver_name: {
    ku: "ناوی شۆفێر",
    ar: "اسم السائق",
  },

  delivery_date: {
    ku: "بەرواری گەیاندن",
    ar: "تاريخ التسليم",
  },
  number: {
    ku: "ژمارە",
    ar: "رقم",
  },
  reciever_phone: {
    ku: "تەلەفۆنی وەرگر",
    ar: "هاتف المستلم",
  },
  good_type: {
    ku: "جۆری کاڵا",
    ar: "نوع متاع",
  },
  page_name: {
    ku: "ناوی پەیج",
    ar: "اسم الصفحة",
  },
  page_number: {
    ku: "ژمارەی پەیج",
    ar: "رقم الصفحة",
  },
  barcode: {
    ku: "بارکۆد",
    ar: "الباركود",
  },
  total_goods: {
    ku: "کۆی مەوادەکان",
    ar: "إجمالي النطاقات",
  },
  total_posts_price: {
    ku: "کۆی نرخی پۆست",
    ar: "إجمالي سعر البريد",
  },
  total_driver_balance: {
    ku: "کۆی حسابی شۆفێر",
    ar: "إجمالي حساب السائق",
  },
  net_balance: {
    ku: "حسابی صافی",
    ar: " الرصيد صافي ",
  },
  for_transportation_and_delivery: {
    ku: "بۆ گواستنەوە و گەیاندن",
    ar: "للنقل والتسليم.",
  },
  error_fetching_drivers_wallet: {
    ku: "هەڵە هەیە لە هێنانی جزدانی شۆفێرەکان",
    ar: "هناك خطأ في إحضار محافظ السائق",
  },
  debt: {
    ku: "قەرز",
    ar: "دين",
  },
  no_posts_founded: {
    ku: "هیچ پۆستێک نەدۆزرایەوە",
    ar: "لم يتم العثور على البريدات",
  },
  post_history: {
    ku: "مێژووی پۆست",
    ar: "تاريخ البريدات",
  },
  more_options: {
    ku: "بژاردەی زیاتر",
    ar: "المزيد من الخيارات",
  },
  receiver_phone: {
    ku: "ژمارەی وەرگر",
    ar: "عدد المستلمين",
  },
  page_owner_phone: {
    ku: "ژ. خاوەن پەیج",
    ar: "رقم مالك الصفحة",
  },
  driver_phone: {
    ku: "ژمارەی شۆفێر",
    ar: "رقم السائق",
  },
  no_administrators_found: {
    ku: "هیچ بەڕێوەبەرێک بەردەست نیە",
    ar: "لا يوجد مشرفون متاحون",
  },
  error_fetching_managemeners_list: {
    ku: "هەڵە هەیە لە هێنانی لیستی بەڕێوەبەرایەتی",
    ar: "يوجد خطأ في حصول قائمة الإدارة",
  },
  error_fetching_manager_types: {
    ku: "هەڵە هەیە لە هێنانی جۆری بەڕێوەبەرەکان",
    ar: "هناك خطأ في حصول نوع المديرين",
  },
  permission: {
    ku: "ڕێگەپێدان",
    ar: "إذن",
  },
  search_for_managers: {
    ku: "گەڕانی بەڕێوەبەر",
    ar: "مدير البحث",
  },
  add_manager: {
    ku: "زیادکردنی بەڕێوەبەر",
    ar: "إضافة مسؤول",
  },
  add_manager_successfully: {
    ku: "بەسەرکەوتوی بەڕێوەبەر زیاد کرا",
    ar: "تمت إضافة المسؤول بنجاح",
  },
  error_adding_admin: {
    ku: "هەڵە هەیە لە زیادکردنی بەڕێوەبەر",
    ar: "هناك خطأ في إضافة المسؤول",
  },
  adding: {
    ku: "زیادکردنی",
    ar: "اضافه",
  },
  update_manager_successfully: {
    ku: "بەڕێوەبەر بە سەرکەوتوی نوێکرایەوە",
    ar: "تم تحديث المدير بنجاح",
  },
  error_update_manager: {
    ku: "هەڵە هەیە لە نوێکردنەوەی بەڕێوەبەر",
    ar: "هناك خطأ في تحديث المسؤول",
  },
  updating: {
    ku: "نوێکردنەوەی",
    ar: "تحديث",
  },
  set_permission_successfully: {
    ku: "بەسەرکەوتوی ڕێگەپێدان دانرا",
    ar: "تم منح الإذن بنجاح",
  },
  set_permission_error: {
    ku: "هەڵە هەیە لە دانانی ڕێگەپێدانەکان",
    ar: "هناك خطأ في إعداد الأذونات",
  },
  managers_not_available: {
    ku: "بەڕێوەبەر بەردەست نیە",
    ar: "المسؤول غير متوفر",
  },
  permission_of: {
    ku: "رێگەپێدانی",
    ar: "إذن",
  },
  set_permission: {
    ku: "دانانی ڕێگەپێدان",
    ar: "إدراج أذونات",
  },
  please_select_user_type: {
    ku: "تکایە جۆری بەکارهێنەر دیاری بکە",
    ar: "الرجاء اختيار نوع المستخدم",
  },
  please_type_title_and_content: {
    ku: "تکایە سەردێڕ و ناوەرۆک بنوسە",
    ar: "الرجاء كتابة العناوين والمحتويات",
  },
  notification_sent_successfully: {
    ku: "بە سەرکەوتوی ئاگانامە نێردرا",
    ar: "تم إرسال الإشعار بنجاح",
  },
  notification_sent_error: {
    ku: "هەڵە هەیە لە لە ناردنی ئاگانامە",
    ar: "هناك خطأ في إرسال الإشعارات.",
  },
  two_weeks_notification_history_cleared_successfully: {
    ku: "ئاگانامەکانی ٢ هەفتە پێش ئێشتا، بە سەرکەوتوی لە بنکەی زانیاری سڕایەوە",
    ar: "الإخطارات قبل أسبوعين ، تم حذفها بنجاح من قاعدة البيانات",
  },
  clearing_two_weeks_notification_history_error: {
    ku: "هەڵە هەیە لە لە سڕینەوەی ئاگانامەکان",
    ar: "يوجد خطأ في حذف الإشعارات",
  },
  user_types: {
    ku: "جۆری بەکارهێنەر",
    ar: "نوع المستخدم",
  },
  send_notification: {
    ku: "ناردنی ئاگانامە",
    ar: "إرسال إشعارات",
  },
  title: {
    ku: "سەردێڕ",
    ar: "عنوان",
  },
  content: {
    ku: "ناوەڕۆک",
    ar: "محتوى",
  },
  getting_list_of_pages_error: {
    ku: "هەڵە هەیە لە هێنانی لیستی پەیجەکان",
    ar: "حدث خطأ أثناء حصول قوائم الصفحات",
  },
  pages: {
    ku: "پەیجەکان",
    ar: "الصفحات",
  },
  search_pages: {
    ku: "گەڕانی پەیجەکان",
    ar: "صفحات البحث",
  },
  add_page: {
    ku: "زیادکردنی پەیج",
    ar: "إضافة صفحة",
  },
  pages_wallet: {
    ku: "جزدانی پەیجەکان",
    ar: "محافظ الصفحة",
  },
  page_add_successfully: {
    ku: "بەسەرکەوتوی پەیج زیاد کرا",
    ar: "تمت إضافة الصفحة بنجاح",
  },
  page_add_error: {
    ku: "هەڵە هەیە لە زیادکردنی پەیج",
    ar: "يوجد خطأ في إضافة صفحة",
  },
  page_update_successfully: {
    ku: "بەسەرکەوتوی پەیج نوێکرایەوە",
    ar: "تم تحديث الصفحة بنجاح",
  },
  page_update_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی پەیج",
    ar: "يوجد خطأ في تحديث الصفحة",
  },
  update_page: {
    ku: "نوێکردنەوەی پەیج",
    ar: "تحديث الصفحة",
  },
  no_transfers_available_between_selected_dates: {
    ku: "هیچ حەواڵەیەک  بەردەست نیە لەنێوان بەروارە هەلبژێردراوەکان",
    ar: "لا توجد نقل متاحة بين التواريخ المحددة",
  },
  user_not_available: {
    ku: "بەکارهێنەر بەردەست نیە",
    ar: "لا توجد نقل متاحة بين التواريخ المحددة ...",
  },
  please_enter_amount: {
    ku: "تکایە بڕ داخل بکە",
    ar: "يرجى إدخال مبلغ.",
  },
  not_enough_information_available: {
    ku: "زانیاری تەواو بەردەست نیە",
    ar: "لا تتوفر معلومات كافية",
  },
  add_transfer_successfully: {
    ku: "حەواڵە بە سەرکەوتوی زیادکرا",
    ar: "تمت إضافة نقل بنجاح",
  },
  add_transfer_error: {
    ku: "هەڵە هەیە لە زیادکردنی حەواڵە",
    ar: "هناك خطأ في إضافة نقل.",
  },
  add_transfer: {
    ku: "زیادکردنی حەواڵە",
    ar: "إضافة نقل",
  },
  transfer_type: {
    ku: "جۆری حەواڵە",
    ar: "نوع نقل",
  },
  pay_money: {
    ku: "پارە دانەوە",
    ar: "دفع المال",
  },
  get_money: {
    ku: "پارە وەرگرتن",
    ar: "استلام الأموال",
  },
  update_transfer_successfully: {
    ku: "حەواڵە بە سەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد نقل بنجاح",
  },
  update_transfer_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی حەواڵە",
    ar: "هناك خطأ في تحديث نقل",
  },
  update_transfer: {
    ku: "نوێکردنەوەی حەواڵە",
    ar: "التحديث نقل ",
  },
  page_account_report: {
    ku: "کەشف حسابی پەیج",
    ar: "تقرير الحساب الصفحة",
  },
  error_fetching_pages_wallet: {
    ku: "هەڵە هەیە لە هێنانی جزدانی پەیجەکان",
    ar: "يوجد خطأ في حصول محافظ الصفحات",
  },
  please_select_printer: {
    ku: "تکایە پرنتەر دیاری بکە",
    ar: "الرجاء اختيار الطابعة",
  },
  error_fetching_posts: {
    ku: "هەڵە هەیە لە هێنانی  پۆست",
    ar: "يوجد خطأ أثناء حصول البريد",
  },
  no_posts_available: {
    ku: "هیچ پۆستێک بەردەست نیە",
    ar: "لا توجد البريدات متاحة",
  },
  drivers_of_selected_posts_are_not_the_same: {
    ku: "سایەقی پۆستەکان جیاوازن",
    ar: "سائق البريد مختلفة",
  },
  no_status_selected: {
    ku: "هیچ حاڵەتێک هەلنەبژێردراوە",
    ar: "لم يتم اختيار أي حالات",
  },
  posts_status_changed_successfully: {
    ku: "بە سەرکەوتوی حاڵەتی پۆستەکان گۆرا",
    ar: "تغيير حالة البريدات بنجاح",
  },
  posts_status_changed_error: {
    ku: "هەڵە هەیە لە گۆڕینی حاڵەتی پۆستەکان",
    ar: "هناك خطأ في تغيير حالة البريد",
  },
  code: {
    ku: "کۆد",
    ar: "رمز",
  },
  search_posts: {
    ku: "گەڕانی پۆستەکان",
    ar: "البحث في البريدات",
  },
  add_post: {
    ku: "زیادکردنی پۆست",
    ar: "إضافة البريد",
  },
  scan_code: {
    ku: "سکانی کۆد",
    ar: "مسح كود",
  },
  change_posts_status: {
    ku: "گۆڕینی حاڵەتی پۆستەکان",
    ar: "تغيير حالة البريد",
  },
  scan_barcode: {
    ku: "سکانکردنی باڕ کۆد",
    ar: "مسح الباركود",
  },
  barcode_post: {
    ku: "باڕ کۆدی پۆست",
    ar: "باركود البريد",
  },
  change_status: {
    ku: "گۆڕینی حاڵەت",
    ar: "تغيير الحالة",
  },
  change: {
    ku: "گۆڕین",
    ar: "تغيير",
  },
  add_post_successfully: {
    ku: "بەسەرکەوتوی پۆستەکە زیادکرا",
    ar: "تمت إضافة البريد بنجاح",
  },
  add_post_error: {
    ku: "هەڵە هەیە لە زیادکردنی پۆست",
    ar: "يوجد خطأ في إضافة البريد",
  },
  clear: {
    ku: "پاککردنەوە",
    ar: "تنظيف",
  },
  update_post_successfully: {
    ku: "بەسەرکەوتوی پۆستەکە نوێکرایەوە",
    ar: "تم تحديث البريد بنجاح",
  },
  update_post_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی پۆست",
    ar: "يوجد خطأ في تحديث البريد",
  },
  update_post: {
    ku: "نوێکردنەوی پۆست",
    ar: "ما بعد التحديث",
  },
  printer_deleted_successfully: {
    ku: "پرنتەر بە سەرکەوتوی سڕایەوە",
    ar: "تم حذف الطابعة بنجاح",
  },
  printer_deleted_error: {
    ku: "هەڵە هەیە لە سڕینەوەی پرنتەر",
    ar: "يوجد خطأ في حذف الطابعة",
  },
  no_printer_available: {
    ku: "هیچ پرنتەرێک  بەردەست نیە",
    ar: "لا توجد طابعات متوفرة",
  },
  error_fetching_list_of_printer: {
    ku: "هەڵە هەیە لە هێنانی لیستی پرنتەرەکان",
    ar: "حدث خطأ أثناء حصول قوائم الطابعات",
  },
  printer_list: {
    ku: "لیستی پرنتەرەکان",
    ar: "قائمة الطابعات",
  },
  label: {
    ku: "لەیبڵ",
    ar: "تسميه",
  },
  add_printer: {
    ku: "زیادکردنی پرنتەر",
    ar: "إضافة طابعة",
  },
  delete_printer: {
    ku: "سڕینەوەی پرنتەر",
    ar: "حذف الطابعة",
  },
  delete_printer_warning: {
    ku: "بە کردتە کردنت لەسەر بەڵێ پرنتەری دیاریکراو بە تەواوی دەسڕێتەوە لە بنکەی زانیاری",
    ar: "بالنقر فوق نعم، سيتم حذف الطابعة المحددة بالكامل من قاعدة البيانات",
  },
  add_printer_successfully: {
    ku: "پرنتەر بە سەرکەوتوی زیادکرا",
    ar: "تمت إضافة الطابعة بنجاح",
  },
  add_printer_error: {
    ku: "هەڵە هەیە لە زیادکردنی پرنتەر",
    ar: "يوجد خطأ في إضافة طابعة",
  },
  update_printer_successfully: {
    ku: "پرنتەر بە سەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد الطابعة بنجاح",
  },
  update_printer_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی پرنتەر",
    ar: "يوجد خطأ في تحديث الطابعة",
  },
  update_printer: {
    ku: "نوێکردنەوەی پرنتەر",
    ar: "تحديث الطابعة",
  },
  driver_money: {
    ku: "پارەی شۆفێر",
    ar: "أموال السائق",
  },
  net_sum: {
    ku: "بڕی صافی",
    ar: "المبلغ الصافي",
  },
  deliver_money: {
    ku: "پارەی گەیاندن",
    ar: "تسليم الأموال",
  },
  sorr_no_page_found: {
    ku: "ببورە هیچ پەڕەیەک بەردەست نیە",
    ar: "عذرا، لا توجد صفحات متوفرة.",
  },
  transfer_id: {
    ku: "ئایدی حەواڵە",
    ar: "معرف الحوالة",
  },
  user_id: {
    ku: "ئایدی بەکارهێنەر",
    ar: "معرف المستخدم",
  },
  owner_phone: {
    ku: "ژمارەی خاوەن",
    ar: "رقم المالك",
  },
  voucher: {
    ku: "کۆدی وەسڵ",
    ar: "رقم وصل",
  },
  post_type: {
    ku: "جۆری پۆست",
    ar: "نوع البريد",
  },
  new: {
    ku: "نوێ",
    ar: "الجديد",
  },
  renew: {
    ku: "گۆڕین",
    ar: "تغيير",
  },
  // only on web
  ony_status_are_sent: {
    ku: "تەنها حاڵەت ڕەوانەکرا، بژاردەکانی تر پشتگوێ خران",
    ar: "تم إرسال الحالة فقط ، وتم رفض خيار آخر",
  },
  delete_cost_warn_message: {
    ku: "بە کرتە کردنی لەسەر بەڵێ خەرجی دیاریکراو بە یەکجاری دەسڕێتەوە لە بنکەی زانیاری",
    ar: "بالنقر فوق نعم ، سيتم حذف التكلفة المحددة نهائيا من قاعدة البيانات",
  },
  cost_deleted_successfully: {
    ku: "خەرجی دیاریکراو بە سەرکەوتوی سڕایەوە",
    ar: "تم حذف التكلفة المحددة بنجاح",
  },
  cost_deleted_error: {
    ku: "هەڵە هەیە لە سڕینەوەی خەرجی دیاریکراو",
    ar: "يوجد خطأ في حذف النفقات المحددة",
  },
  cost_types: {
    ku: "جۆری خەرجی",
    ar: "نوع التكلفة",
  },
  costs_types: {
    ku: "جۆری خەرجیەکان",
    ar: "نوع المصاريف",
  },
  get_cost_types_list_error: {
    ku: "هەڵە هەیە لە هێنانی لیستی جۆری خەرجیەکان",
    ar: "هناك خطأ في حصول قائمة أنواع النفقات",
  },
  costtype_deleted_successfully: {
    ku: "جۆری خەرجی دیاریکراو بە سەرکەوتوی سڕایەوە",
    ar: "تم حذف نوع التكلفة المحدد بنجاح",
  },
  costtype_deleted_error: {
    ku: "هەڵە هەیە لە سڕینەوەی جۆری خەرجی دیاریکراو",
    ar: "يوجد خطأ في حذف نوع التكلفة المحدد",
  },
  delete_costtype_warn_message: {
    ku: "بە کرتە کردنی لەسەر بەڵێ جۆری خەرجی دیاریکراو بە یەکجاری دەسڕێتەوە لە بنکەی زانیاری",
    ar: "حذف نوع التكلفة المحدد من قاعدة البيانات نهائيا بالنقر فوق نعم",
  },
  add_costtype_successfully: {
    ku: "جۆری خەرجی بە سەرکەوتوی زیادکرا",
    ar: "تمت إضافة نوع الإنفاق بنجاح",
  },
  add_costtype_error: {
    ku: "هەڵە هەیە لە زیادکردنی جۆری خەرجی",
    ar: "هناك خطأ في إضافة نوع النفقات",
  },
  add_costtype: {
    ku: "زیادکردنی جۆری خەرجی",
    ar: "إضافة نوع التكلفة",
  },
  missing_field_to_update_costtype: {
    ku: "زانیار تەواو بەردەست نیە بۆ نوێکردنەوەی جۆری خەرجی",
    ar: "البيانات غير متوفرة بالكامل لتحديث نوع التكلفة",
  },
  costtype_update_successfully: {
    ku: "جۆری خەرجی بە سەرکەوتوی نوێکرایەوە",
    ar: "تم تجديد نوع الإنفاق بنجاح",
  },
  costtype_update_error: {
    ku: "هەڵە هەیە لە نوێکردنەوەی جۆری خەرجی",
    ar: "يوجد خطأ في تحديث نوع التكلفة",
  },
  update_costtype: {
    ku: "نوێکردنەوەی جۆری خەرجی",
    ar: "تحديث نوع التكلفة",
  },
  costtype: {
    ku: "جۆری خەرجی",
    ar: "نوع التكلفة",
  },
  driver_price_total: {
    ku: "کۆی گشتی نرخی شۆفێر",
    ar: "إجمالي سعر السائق",
  },
  city_price_total: {
    ku: "کۆی گشتی نرخی شار",
    ar: "إجمالي سعر المدينة",
  },
  price_total: {
    ku: "کۆی گشتی نرخ",
    ar: "السعر الإجمالي",
  },
  intial_price: {
    ku: "نرخی سەرەتا",
    ar: "السعر الأول",
  },
  net_price: {
    ku: "نرخی سافی",
    ar: "السعر سافی",
  },
  export: {
    ku: "هەناردە",
    ar: "تصدير",
  },
  empty: {
    ku: "",
    ar: "",
  },
}

// translate("empty", data2.lang)

const translate = (key, langCode) => {
  try {
    return translation[key][langCode]
  } catch (error) {
    return `${key}`
  }
}

export { translate }
