import { format } from "date-fns"
import { decode } from "jsonwebtoken"
import axios from "./axiosConfig"
import { translate } from "./lang"
import {
  fullSpeedPostLogo,
  hiloadLogo,
  lawapostLogo,
  pshtiwanPostLogo,
  samanPostLogo,
  nasihPostLogo,
  zupostLogo,
  FirstPostKrdLogo,
} from "./logo"

// error generated to show custom error message and error message from axios
const errorGenerator = (error, toastError, message) => {
  if (error?.response?.status === 401) {
    return
  }
  if (error.response) {
    toastError(`${message}    (${error.response?.status})`)
  } else {
    toastError(`${message}`)
  }
}

// inject token to every axios request
const createAxiosInstance = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }
}

// check for logged in user if is authenticated or not, with validate token expiration time
const isAuthenticated = (user, logout) => {
  if (Object.keys(user).length === 0 && user.constructor === Object) {
    createAxiosInstance(null)
    return false
  }

  if (user && user["token"]) {
    const token = user["token"]

    var decodedToken = decode(token, { complete: true })
    const secondsSinceEpoch = Math.round(Date.now() / 1000)
    if (secondsSinceEpoch - decodedToken.payload.exp > 0) {
      createAxiosInstance(null)
      // logout()
      return false
    } else {
      createAxiosInstance(token)
      return true
    }
  } else {
    createAxiosInstance(null)
    // logout()
    return false
  }
}

// func 1 - validate passsword
const scorePassword = (pass) => {
  let score = 0
  if (!pass) return score

  // award every unique letter until 5 repetitions
  let letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  }

  let variationCount = 0
  for (let check in variations) {
    variationCount += variations[check] === true ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
// func 2 - validate passsword
const checkPassStrength = (pass, data2) => {
  let score = scorePassword(pass)
  if (score > 80) return `${translate("strong", data2.lang)}`
  if (score > 60) return `${translate("good", data2.lang)}`
  if (score >= 30) return `${translate("weak", data2.lang)}`

  return `${translate("weak", data2.lang)}`
}

const userSearchUrls = (type) => {
  let finalUrl = ""
  switch (type) {
    case "root":
      finalUrl = "user/root/search"
      break
    case "admin":
      finalUrl = "user/search"
      break
    case "driver":
      finalUrl = "user/driver/search"
      break
    case "page-owner":
      finalUrl = "user/page-owner/search"
      break
    default:
      finalUrl = ""
  }

  return finalUrl
}

const userAddEditUrls = (type) => {
  let finalUrl = ""
  switch (type) {
    case "root":
      finalUrl = "user"
      break
    case "admin":
      finalUrl = "user"
      break
    case "driver":
      finalUrl = "user/driver"
      break
    case "page-owner":
      finalUrl = "user/page-owner"
      break
    default:
      finalUrl = ""
  }

  return finalUrl
}

const isValidObject = (obj) => {
  for (let key in obj) {
    if (!obj[key]) return false
  }
  return true
}

const getLastWeekDate = () => {
  let firstDay = new Date()
  let lastWeek = new Date(firstDay.getTime() - 7 * 24 * 60 * 60 * 1000)
  return lastWeek
}

const getFormattedDate = (string) => {
  let dt
  if (string === undefined) {
    dt = new Date()
  } else {
    dt = new Date(string)
  }

  const year = dt.getFullYear()
  const month = (dt.getMonth() + 1).toString().padStart(2, "0")
  const day = dt.getDate().toString().padStart(2, "0")

  const hour = dt.getHours().toString().padStart(2, "0")
  const minute = dt.getMinutes().toString().padStart(2, "0")
  const second = dt.getSeconds().toString().padStart(2, "0")

  return (
    year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second
  )
}

const getFormattedDateDisplay = (string) => {
  let dt

  if (string === "" || string === undefined || string === null) {
    return "..."
  } else {
    dt = new Date(Date.parse(string))
  }

  try {
    return format(dt, "yyyy-MM-dd hh:mm aaaaa'm'")
      .replace("am", "بەیانی")
      .replace("pm", "ئێوارە")
  } catch (error) {
    return "..."
  }
}

function numberWithCommas(x) {
  if (typeof x === "number") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return x
  }
}

const getFirstLastDayOfMonth = (date) => {
  return {
    first: getFormattedDate(new Date(date.getFullYear(), date.getMonth(), 1)),
    last: getFormattedDate(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    ),
  }
}

const messageGenerator = (key, message) => {
  let messages = {
    "user-phone-confliced": "بەکارهێنەر بەهەمان ژمارە تەلەفون بونی هەیە",
    "token-expired": "ڕێگەپێدانی بەسەرچووە، تکایە دوبارە چونەژورەوە ئەنجام بدە",
    "user-not-autherized": "ڕێگەپێدراونیت بۆ ئەم کردارە",
    "phone-or-password-incorrect": "ژمارە مۆبایل یان وشەی تێپەڕ دروست نیە",
    "logout-successfull": "دەرچون بە سەرکەوتوی تۆمار کرا",
  }

  if (messages[key]) {
    return messages[key]
  } else {
    return message
  }
}

const colorGenerator = (str) => {
  // java String#hashCode

  str = str + "cool"
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  var c = (hash & 0x00ffffff).toString(16).toUpperCase()

  return "#" + "00000".substring(0, 6 - c.length) + c
}

const colors = [
  {
    name: "لە ئۆفیس (من المکتب)",
    statusID: 1,
    en_name: "in_office",
    color: "#00FF00",
  },
  {
    name: "لای شۆفێر (مآخوذ من قبل سائق)",
    statusID: 2,
    en_name: "taken_by_driver",
    color: "#800080	",
  },
  {
    name: "گەشتوە ( واصل)",
    statusID: 3,
    en_name: "arrived",
    color: "#008080",
  },
  {
    name: "جواب ناداتەوە (لا اجابە)",
    statusID: 6,
    en_name: "no_answer",
    color: "#808080",
  },
  {
    name: "تەلەفۆنی داخراوە (هاتف مقفل)",
    statusID: 7,
    en_name: "phone_locked",
    color: "#B565A7",
  },
  {
    name: "کێشەی تێدایە (لدیها مشکیلە)",
    statusID: 8,
    en_name: "have_problem",
    color: "#FF00FF",
  },
  {
    name: "نایەوێت ( مرفوض)",
    statusID: 9,
    en_name: "rejected",
    color: "#FF0000",
  },
  {
    name: "دوبارەیە (مضاعفە)",
    statusID: 10,
    en_name: "douplicate",
    color: "#8F00FF",
  },
  {
    name: "گەڕاوە بۆ ئۆفیس (عاد الی مکتب)",
    statusID: 11,
    en_name: "returned_to_office",
    color: "#FF7F7F",
  },
  {
    name: "گەڕاوە بۆ پەیج ( عاد الی الصفحە)",
    statusID: 12,
    en_name: "returned_to_page",
    color: "#808000",
  },
  {
    name: "دواخرا (یۆجل)",
    statusID: 13,
    en_name: "postpone",
    color: "#EFC050",
  },
]

const getColorByStatusName = (name) => {
  let color = "#fff"
  colors.forEach((element) => {
    if (element.en_name === name) {
      color = element.color
    }
  })

  return color
}

const getColorByStatusID = (id) => {
  let color = "#fff"
  colors.forEach((element) => {
    if (element.statusID === id) {
      color = element.color
    }
  })

  return color
}

const getEnNameByStatusID = (id) => {
  let name = "..."
  colors.forEach((element) => {
    if (element.statusID === id) {
      name = element.en_name
    }
  })

  return name
}

const getStatusByEnName = (en_name) => {
  let tempElement = {}
  colors.forEach((element) => {
    if (element.en_name === en_name) {
      tempElement = { ...element }
    }
  })

  return tempElement
}

const convertToArabic = (str) => {
  let charsTable = {
    ۆ: "و",
    پ: "ب",
    گ: "ک",
    ژ: "ز",
    ڤ: "ف",
    ێ: "ی",
    ڕ: "ر",
    ڵ: "ل",
    چ: "ج",
    ی: "ي",
    ە: "ة",
    ه: "ه",
    ا: "ا",
    ک: "ك",
  }

  if (typeof str === "string") {
    Object.keys(charsTable).forEach((key) => {
      str = str.replaceAll(key, charsTable[key])
    })
  }

  return str
}

const projectInfoObject = {
  localhost: {
    companyName: "هایلۆد سیستەم",
    logo: hiloadLogo,
    baseURL: "https://api.hiloadsystem.com/",
    printURL: "https://print.hiloadsystem.com/",
    mainColor: "#4f4f4f",
    appName: "Hiload System",
  },
  "web.hiloadsystem.com": {
    companyName: "هایلۆد سیستەم",
    logo: hiloadLogo,
    baseURL: "https://api.hiloadsystem.com/",
    printURL: "https://print.hiloadsystem.com/",
    mainColor: "#4f4f4f",
    appName: "Hiload System",
  },
  "fullspeedpost.com": {
    companyName: "گەیاندنی خێرا",
    logo: fullSpeedPostLogo,
    baseURL: "https://api.fullspeedpost.com/",
    printURL: "https://print.fullspeedpost.com/",
    mainColor: "#4f4f4f",
    appName: "Full Speed",
  },
  "samanpost.net": {
    companyName: "سامان پۆست",
    logo: samanPostLogo,
    baseURL: "https://api.samanpost.net/",
    printURL: "https://print.samanpost.net/",
    mainColor: "#4f4f4f",
    appName: "Saman Post",
  },
  "pshtiwanpost.com": {
    companyName: "پشتیوان پۆست",
    logo: pshtiwanPostLogo,
    baseURL: "https://api.pshtiwanpost.com/",
    printURL: "https://print.pshtiwanpost.com/",
    mainColor: "#142F43",
    appName: "Pshtiwan System",
  },
  "lawapost.com": {
    companyName: "لاوە پۆست",
    logo: lawapostLogo,
    baseURL: "https://api.lawapost.com/",
    printURL: "https://print.lawapost.com/",
    mainColor: "#142F43",
    appName: "Lawa System",
  },
  "nasihpost.net": {
    companyName: "ناسح پۆست",
    logo: nasihPostLogo,
    baseURL: "https://api.nasihpost.net/",
    printURL: "https://print.nasihpost.net/",
    mainColor: "#142F43",
    appName: "Nasih System",
  },
  "zupost.net": {
    companyName: "زوو پۆست",
    logo: zupostLogo,
    baseURL: "https://api.zupost.net/",
    printURL: "https://print.zupost.net/",
    mainColor: "#142F43",
    appName: "ZuPost System",
  },
  "firstpostkrd.com": {
    companyName: "فێرست پۆست",
    logo: FirstPostKrdLogo,
    baseURL: "https://api.firstpostkrd.com/",
    printURL: "https://print.firstpostkrd.com/",
    mainColor: "#142F43",
    appName: "FirstPost System",
  },
}

const projectInfo = projectInfoObject[window.location.hostname]

const checkPostType = (postType) => {
  if (postType === "1" || postType === 1) {
    return "renew"
  } else {
    return "new"
  }
}

// thousand seperator
const thousandSeperator = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export {
  convertToArabic,
  errorGenerator,
  isAuthenticated,
  checkPassStrength,
  userSearchUrls,
  userAddEditUrls,
  isValidObject,
  getLastWeekDate,
  getFormattedDate,
  numberWithCommas,
  getFirstLastDayOfMonth,
  messageGenerator,
  colorGenerator,
  getColorByStatusName,
  getColorByStatusID,
  getFormattedDateDisplay,
  getStatusByEnName,
  projectInfo,
  checkPostType,
  thousandSeperator,
  getEnNameByStatusID,
}
