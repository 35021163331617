import { jssPreset, StylesProvider } from "@material-ui/core/styles"
import { create } from "jss"
import rtl from "jss-rtl"

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function RTL2(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>
}

export default RTL2
