import Backdrop from "@material-ui/core/Backdrop"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import orange from "@material-ui/core/colors/orange"
import makeStyles from "@material-ui/core/styles/makeStyles"
import React, { Suspense, useState } from "react"
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import { setData as setDatafn } from "../actions/dataActions"
import { logoutUser } from "../actions/userActions"
import axios from "../axiosConfig"
import { translate } from "../lang"
import { isAuthenticated } from "../utils"
import PermissionRoute from "./utils/PermissionRoute"
import PrivateRoute from "./utils/PrivateRoute"
import ScrollToTop from "./utils/ScrollToTop"
import SigninRoute from "./utils/SigninRoute"

const Dashboard = React.lazy(() => import("./Dashboard"))
const Signin = React.lazy(() => import("./Signin"))
const Cities = React.lazy(() => import("./cities/Cities"))
const AddCity = React.lazy(() => import("./cities/AddCity"))
const Categories = React.lazy(() => import("./categories/Categories"))
const NotFoundContent = React.lazy(() => import("./utils/NotFoundContent"))
const Posts = React.lazy(() => import("./posts/Posts"))
const AddPost = React.lazy(() => import("./posts/AddPost"))
const EditPost = React.lazy(() => import("./posts/EditPost"))
const Pages = React.lazy(() => import("./pages/Pages"))
const AddPage = React.lazy(() => import("./pages/AddPage"))
const EditPage = React.lazy(() => import("./pages/EditPage"))

const PageWallet = React.lazy(() => import("./pages/Page_Wallet"))
const TransfersPage = React.lazy(() => import("./pages/TransfersPage"))
const AddTransferPage = React.lazy(() => import("./pages/AddTransferPage"))
const EditTransferPage = React.lazy(() => import("./pages/EditTransferPage"))

const PagesJzdanPrint = React.lazy(() => import("./pages/PagesJzdanPrint"))

const TransfersPageNew = React.lazy(() => import("./pages/TransfersPage_New"))
const TransfersPageNewPrint = React.lazy(() =>
  import("./pages/TransfersPage_NewPrint")
)
const AddTransferPageNew = React.lazy(() =>
  import("./pages/AddTransferPage_New")
)
const EditTransferPageNew = React.lazy(() =>
  import("./pages/EditTransferPage_New")
)
const ReportPage = React.lazy(() => import("./pages/ReportPage"))
// const ReportPagePrint = React.lazy(() => import("./pages/ReportPagePrint"))
const PageOwner = React.lazy(() => import("./pages/PagesProduct"))
const PageKashf = React.lazy(() => import("./pages/PageKashf"))
const JzdanPages = React.lazy(() => import("./pages/JzdanPages"))
const Drivers = React.lazy(() => import("./drivers/Drivers"))
const AddDriver = React.lazy(() => import("./drivers/AddDriver"))
const EditDriver = React.lazy(() => import("./drivers/EditDriver"))
const TransfersDriverNew = React.lazy(() =>
  import("./drivers/TransfersDriver_New")
)
const TransfersDriverNewPrint = React.lazy(() =>
  import("./drivers/TransfersDriver_NewPrint")
)
const AddTransferDriverNew = React.lazy(() =>
  import("./drivers/AddTransferDriver_New")
)
const EditTransferDriverNew = React.lazy(() =>
  import("./drivers/EditTransferDriver_New")
)

const DriverWallet = React.lazy(() => import("./drivers/Driver_Wallet"))
const TransfersDriver = React.lazy(() => import("./drivers/TransfersDriver"))
const AddTransferDriver = React.lazy(() =>
  import("./drivers/AddTransferDriver")
)
const EditTransferDriver = React.lazy(() =>
  import("./drivers/EditTransferDriver")
)

const ReportDriver = React.lazy(() => import("./drivers/ReportDriver"))
const DriverPosts = React.lazy(() => import("./drivers/DriverPosts"))
const DriverKashf = React.lazy(() => import("./drivers/DriverKashf"))
const DriverPostsPrint = React.lazy(() => import("./drivers/DriverPostsPrint"))
const JzdanDrivers = React.lazy(() => import("./drivers/JzdanDrivers"))
const DriversJzdanPrint = React.lazy(() =>
  import("./drivers/DriversJzdanPrint")
)
// const DriverPagePrint = React.lazy(() => import("./drivers/DriverReportPrint"))
const Managers = React.lazy(() => import("./manager/Managers"))
const AddManager = React.lazy(() => import("./manager/AddManager"))
const EditManager = React.lazy(() => import("./manager/EditManager"))
const PermissionAdmin = React.lazy(() => import("./manager/PermissionAdmin"))
const Benefit = React.lazy(() => import("./benefit/Benefit"))

const Costs = React.lazy(() => import("./costs/Costs"))
const AddCost = React.lazy(() => import("./costs/AddCost"))
const EditCost = React.lazy(() => import("./costs/EditCost"))

const CostTypes = React.lazy(() => import("./costtypes/CostTypes"))
const AddCostType = React.lazy(() => import("./costtypes/AddCostType"))
const EditCostType = React.lazy(() => import("./costtypes/EditCostType"))

const Printers = React.lazy(() => import("./printers/Printers"))
const AddPrinters = React.lazy(() => import("./printers/AddPrinters"))
const EditPrinters = React.lazy(() => import("./printers/EditPrinters"))

const History = React.lazy(() => import("./history/History"))
const HistoryPrint = React.lazy(() => import("./history/HistoryPrint"))
const Notification = React.lazy(() => import("./notification/Notification"))
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"))

const toastSuccess = (message) => {
  toast.success(message)
}

const toastError = (message) => {
  toast.error(message)
}

const toastWarn = (message) => {
  toast.warn(message)
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: orange[500],
  },
}))

function App({ user, data2, setData, logout }) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (401 === error.response?.status) {
        setData({
          message: translate("expire_token_message", data2.lang),
        })
        isAuthenticated(user, logout)
      } else {
        return Promise.reject(error)
      }
    }
  )

  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress color="inherit" />
        </Box>
      }
    >
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Router>
        <ScrollToTop>
          <Switch>
            <SigninRoute exact path="/Signin">
              <Signin setLoading={setLoading}></Signin>
            </SigninRoute>

            <PrivateRoute exact path="/">
              <Dashboard setLoading={setLoading} />
            </PrivateRoute>

            <PrivateRoute exact path="/posts">
              <PermissionRoute
                permissions={["root", "admin", "driver", "page-owner"]}
              >
                <Posts setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/posts/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddPost setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/posts/edit">
              <PermissionRoute permissions={["root", "admin", "driver"]}>
                <EditPost setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages">
              <PermissionRoute permissions={["root", "admin"]}>
                <Pages setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/kashf">
              <PermissionRoute permissions={["page-owner"]}>
                <PageKashf setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/product">
              <PermissionRoute permissions={["page-owner"]}>
                <PageOwner setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/wallet">
              <PermissionRoute permissions={["page-owner"]}>
                <PageWallet setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddTransferPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditTransferPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers_new">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersPageNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers_new/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersPageNewPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers_new/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddTransferPageNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/transfers_new/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditTransferPageNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/report">
              <PermissionRoute permissions={["root", "admin"]}>
                <ReportPage setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/jzdan">
              <PermissionRoute permissions={["root", "admin"]}>
                <JzdanPages setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/pages/jzdan/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <PagesJzdanPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            {/* <PrivateRoute exact path="/pages/report/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <ReportPagePrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute> */}

            <PrivateRoute exact path="/drivers">
              <PermissionRoute permissions={["root", "admin"]}>
                <Drivers setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers_new">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersDriverNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers_new/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersDriverNewPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers_new/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddTransferDriverNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers_new/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditTransferDriverNew setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers">
              <PermissionRoute permissions={["root", "admin"]}>
                <TransfersDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/wallet">
              <PermissionRoute permissions={["driver"]}>
                <DriverWallet setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddTransferDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/transfers/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditTransferDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/report">
              <PermissionRoute permissions={["root", "admin"]}>
                <ReportDriver setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/posts">
              <PermissionRoute permissions={["root", "admin"]}>
                <DriverPosts setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/kashf">
              <PermissionRoute permissions={["driver"]}>
                <DriverKashf setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/posts/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <DriverPostsPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/jzdan">
              <PermissionRoute permissions={["root", "admin"]}>
                <JzdanDrivers setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/drivers/jzdan/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <DriversJzdanPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            {/* <PrivateRoute exact path="/drivers/report/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <DriverPagePrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute> */}

            <PrivateRoute exact path="/managers">
              <PermissionRoute permissions={["root"]}>
                <Managers setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/managers/add">
              <PermissionRoute permissions={["root"]}>
                <AddManager setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/managers/edit">
              <PermissionRoute permissions={["root"]}>
                <EditManager setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/managers/permission">
              <PermissionRoute permissions={["root"]}>
                <PermissionAdmin setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/benefit">
              <PermissionRoute permissions={["root"]}>
                <Benefit setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costs">
              <PermissionRoute permissions={["root", "admin"]}>
                <Costs setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costs/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddCost setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costs/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditCost setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costtypes">
              <PermissionRoute permissions={["root", "admin"]}>
                <CostTypes setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costtypes/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddCostType setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/costtypes/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditCostType setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/printers">
              <PermissionRoute permissions={["root", "admin"]}>
                <Printers setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/printers/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddPrinters setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/printers/edit">
              <PermissionRoute permissions={["root", "admin"]}>
                <EditPrinters setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            {/* ------------------------------------------------------------------------------- */}

            <PrivateRoute exact path="/cities">
              <PermissionRoute permissions={["root", "admin"]}>
                <Cities setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/cities/add">
              <PermissionRoute permissions={["root", "admin"]}>
                <AddCity setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/categories">
              <PermissionRoute permissions={["root", "admin"]}>
                <Categories setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/history">
              <PermissionRoute permissions={["root", "admin"]}>
                <History setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/history/print">
              <PermissionRoute permissions={["root", "admin"]}>
                <HistoryPrint setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <PrivateRoute exact path="/notification">
              <PermissionRoute permissions={["root", "admin"]}>
                <Notification setLoading={setLoading} />
              </PermissionRoute>
            </PrivateRoute>

            <Route exact path="/privacy">
              <PrivacyPolicy />
            </Route>

            <Route path="*" component={NotFoundContent}></Route>
          </Switch>
        </ScrollToTop>
      </Router>

      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Suspense>
  )
}

const mapStateToProps = (state) => {
  return { user: state.user, data2: state.data }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutUser())
    },
    setData: (data) => {
      dispatch(setDatafn(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

export { toastSuccess, toastError, toastWarn }
