import { connect } from "react-redux"
import { Route } from "react-router-dom"

function PermissionPainter({ children, user, permissions, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => permissions.includes(user.type) && children}
    />
  )
}

const mapStateToProps = (state) => {
  return { user: state.user }
}

export default connect(mapStateToProps)(PermissionPainter)
